var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wrapArray = exports.default = void 0;
/** @module */
/**
 * Wrap value in array if it is not an array.
 *
 * @function wrapArray
 * @param  {*} valOrArr
 * @return {Array}
 */
const wrapArray = valOrArr => {
  if (valOrArr == null || valOrArr === false) return [];
  if (valOrArr instanceof Array) return valOrArr;
  return [valOrArr];
};
exports.wrapArray = wrapArray;
var _default = exports.default = wrapArray;
export default exports;