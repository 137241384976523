import _postcss_plugin2 from "../helpers/postcss_plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.printable = exports.postprocess = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

const marpitPrintContainerStyle = `
html, body {
  background-color: #fff;
  margin: 0;
  page-break-inside: avoid;
  break-inside: avoid-page;
}
`.trim();

/**
 * Marpit PostCSS printable plugin.
 *
 * Make printable slide deck as PDF.
 *
 * @param {Object} opts
 * @param {string} opts.width
 * @param {string} opts.height
 * @function printable
 */
const printable = exports.printable = (0, _postcss_plugin.default)("marpit-postcss-printable", opts => css => {
  css.walkAtRules("media", rule => {
    if (rule.params === "marpit-print") rule.remove();
  });
  css.first.before(`
@page {
  size: ${opts.width} ${opts.height};
  margin: 0;
}

@media marpit-print {
  section {
    page-break-before: always;
    break-before: page;
  }

  section, section * {
    -webkit-print-color-adjust: exact !important;
    animation-delay: 0s !important;
    animation-duration: 0s !important;
    color-adjust: exact !important;
    transition: none !important;
  }

  :marpit-container > svg[data-marpit-svg] {
    display: block;
    height: 100vh;
    width: 100vw;
  }
}
`.trim());
});

/**
 * The post-process PostCSS plugin of Marpit printable plugin.
 *
 * @function postprocess
 */
const postprocess = exports.postprocess = (0, _postcss_plugin.default)("marpit-postcss-printable-postprocess", () => css => css.walkAtRules("media", rule => {
  if (rule.params !== "marpit-print") return;
  rule.params = "print";
  rule.first.before(marpitPrintContainerStyle);
}));
var _default = exports.default = printable;
export default exports;