import _plugin2 from "../plugin";
import _advanced2 from "./background_image/advanced";
import _apply2 from "./background_image/apply";
import _parse2 from "./background_image/parse";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.backgroundImage = void 0;
var _plugin = _interopRequireDefault(_plugin2);
var _advanced = _interopRequireDefault(_advanced2);
var _apply = _interopRequireDefault(_apply2);
var _parse = _interopRequireDefault(_parse2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit background image plugin.
 *
 * Convert image token to backgrounds when the alternate text includes `bg`.
 *
 * When Marpit inline SVG mode is disabled, the image will convert to
 * `backgroundImage` and `backgroundSize` spot directive. It supports only
 * single background and resizing by using CSS.
 *
 * When inline SVG mode is enabled, the plugin enables advanced background mode.
 * In addition to the basic background implementation, it supports multiple
 * background images, filters, and split background.
 *
 * @function backgroundImage
 * @param {MarkdownIt} md markdown-it instance.
 */
function _backgroundImage(md) {
  (0, _parse.default)(md);
  (0, _apply.default)(md);
  (0, _advanced.default)(md);
}
const backgroundImage = exports.backgroundImage = (0, _plugin.default)(_backgroundImage);
var _default = exports.default = backgroundImage;
export default exports;