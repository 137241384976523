import _postcss_plugin2 from "../../helpers/postcss_plugin";
import _parse2 from "./parse";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importReplace = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
var _parse = _interopRequireDefault(_parse2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit PostCSS import replace plugin.
 *
 * Replace parsed `@import` / `@import-theme` rules.
 *
 * Please see {@link module:postcss/import/parse} about the specification of
 * each syntax.
 *
 * @function importReplace
 * @param {ThemeSet} themeSet ThemeSet instance.
 */
const importReplace = (themeSet, importedThemes = []) => (0, _postcss_plugin.default)("marpit-postcss-import-replace", () => ({
  plugins: [(0, _parse.default)(), (0, _postcss_plugin.default)("marpit-postcss-import-replace-processor", () => (css, {
    postcss
  }) => {
    const prepends = [];
    css.walk(node => {
      const name = node.marpitImportParse;
      if (name) {
        const theme = themeSet.get(name);
        if (theme) {
          if (importedThemes.includes(name)) throw new Error(`Circular "${name}" theme import is detected.`);
          const processed = postcss([importReplace(themeSet, [...importedThemes, name])]).process(theme.css);
          if (node.name === "import") {
            node.replaceWith(processed.root);
          } else {
            node.remove();
            prepends.unshift(processed.root);
          }
        }
      }
    });
    for (const root of prepends) css.first.before(root);
  })()]
}));
exports.importReplace = importReplace;
var _default = exports.default = importReplace;
export default exports;