import _split2 from "../helpers/split";
import _wrap_tokens2 from "../helpers/wrap_tokens";
import _plugin2 from "../plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.slide = exports.defaultAnchorCallback = exports.default = void 0;
var _split = _split2;
var _wrap_tokens = _wrap_tokens2;
var _plugin = _interopRequireDefault(_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

const defaultAnchorCallback = i => `${i + 1}`;

/**
 * Marpit slide plugin.
 *
 * Split markdown-it tokens into the slides by horizontal rule. Each slides
 * will be wrapped by section element.
 *
 * @function slide
 * @param {MarkdownIt} md markdown-it instance.
 * @param {Object} [opts]
 * @param {Object} [opts.attributes] The `<section>` element attributes by
 *     key-value pairs.
 * @param {(boolean|Marpit~AnchorCallback)} [opts.anchor=true] If true, assign
 *     the anchor with the page number starting from 1. You can customize anchor
 *     name by passing callback function.
 */
exports.defaultAnchorCallback = defaultAnchorCallback;
function _slide(md, opts = {}) {
  const anchor = opts.anchor === undefined ? true : opts.anchor;
  const anchorCallback = (() => {
    if (typeof anchor === "function") return anchor;
    if (anchor) return defaultAnchorCallback;
    return () => undefined;
  })();
  md.core.ruler.push("marpit_slide", state => {
    if (state.inlineMode) return;
    const splittedTokens = (0, _split.split)(state.tokens, t => t.type === "hr" && t.level === 0, true);
    const {
      length: marpitSlideTotal
    } = splittedTokens;
    state.tokens = splittedTokens.reduce((arr, slideTokens, marpitSlide) => {
      const firstHr = slideTokens[0] && slideTokens[0].type === "hr" ? slideTokens[0] : undefined;
      const mapTarget = firstHr || slideTokens.find(t => t.map);
      return [...arr, ...(0, _wrap_tokens.wrapTokens)(state.Token, "marpit_slide", {
        ...(opts.attributes || {}),
        tag: "section",
        id: anchorCallback(marpitSlide),
        open: {
          block: true,
          meta: {
            marpitSlide,
            marpitSlideTotal,
            marpitSlideElement: 1
          },
          map: mapTarget ? mapTarget.map : [0, 1]
        },
        close: {
          block: true,
          meta: {
            marpitSlide,
            marpitSlideTotal,
            marpitSlideElement: -1
          }
        }
      }, slideTokens.slice(firstHr ? 1 : 0))];
    }, []);
  });
}
const slide = exports.slide = (0, _plugin.default)(_slide);
var _default = exports.default = slide;
export default exports;