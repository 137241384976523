import _postcss_plugin2 from "../../helpers/postcss_plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importHoisting = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit PostCSS import hoisting plugin.
 *
 * Hoist `@charset` and `@import` at-rule to the beginning of CSS. Marpit is
 * manipulating CSS with many PostCSS plugins, so sometimes a few at-rules
 * cannot keep specification.
 *
 * This plugin takes care of hoisting for invalid at-rules.
 *
 * @function importHoisting
 */
const importHoisting = exports.importHoisting = (0, _postcss_plugin.default)("marpit-postcss-import-hoisting", () => css => {
  const hoisted = {
    charset: undefined,
    imports: []
  };
  css.walkAtRules(rule => {
    if (rule.name === "charset") {
      rule.remove();
      if (!hoisted.charset) hoisted.charset = rule;
    } else if (rule.name === "import") {
      hoisted.imports.push(rule.remove());
    }
  });
  const {
    first
  } = css

  // Hoist at-rules
  ;

  [hoisted.charset, ...hoisted.imports].filter(r => r).forEach((rule, idx) => {
    // Strip whitespace from the beginning of first at-rule
    const prependRule = idx === 0 ? rule.clone({
      raws: {
        before: undefined
      }
    }) : rule;
    first.before(prependRule);
  });
});
var _default = exports.default = importHoisting;
export default exports;