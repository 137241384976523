import * as _cssesc3 from "cssesc";
var _cssesc2 = "default" in _cssesc3 ? _cssesc3.default : _cssesc3;
import _postcss_plugin2 from "../../helpers/postcss_plugin";
import _wrap_array2 from "../../helpers/wrap_array";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pseudoSelectorReplace = exports.default = void 0;
var _cssesc = _interopRequireDefault(_cssesc2);
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
var _wrap_array = _wrap_array2;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

const buildSelector = elms => elms.map(e => {
  const classes = new Set((e.class || "").split(/\s+/).filter(c => c));
  let element = [e.tag, ...classes].map(c => (0, _cssesc.default)(c, {
    isIdentifier: true
  })).join(".");
  if (e.id) element += `#${(0, _cssesc.default)(e.id, {
    isIdentifier: true
  })}`;
  return element;
}).join(" > ");

/**
 * Marpit PostCSS pseudo selector replace plugin.
 *
 * Replace `:marpit-container` and `:marpit-slide` pseudo selector into
 * container element(s).
 *
 * @function pseudoSelectorReplace
 * @param {Element|Element[]} [elements] Container elements
 * @param {Element|Element[]} [slideElements={ tag: 'section' }] Slide elements
 */
const pseudoSelectorReplace = exports.pseudoSelectorReplace = (0, _postcss_plugin.default)("marpit-postcss-pseudo-selector-replace", (elements, slideElements = {
  tag: "section"
}) => {
  const container = buildSelector([...(0, _wrap_array.wrapArray)(elements)]);
  const section = buildSelector([...(0, _wrap_array.wrapArray)(slideElements)]);
  return css => css.walkRules(rule => {
    rule.selectors = rule.selectors.map(selector => selector.replace(/:marpit-container(?![\w-])/g, container).replace(/:marpit-slide(?![\w-])/g, section).replace(/^\s*>\s*/, ""));
  });
});
var _default = exports.default = pseudoSelectorReplace;
export default exports;