import _theme2 from "../theme";
import _symbol2 from "./symbol";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scaffoldTheme = exports.default = void 0;
var _theme = _interopRequireDefault(_theme2);
var _symbol = _interopRequireDefault(_symbol2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

const css = `
section {
  width: 1280px;
  height: 720px;

  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  scroll-snap-align: center center;
}

section::after {
  bottom: 0;
  content: attr(data-marpit-pagination);
  padding: inherit;
  pointer-events: none;
  position: absolute;
  right: 0;
}

section:not([data-marpit-pagination])::after {
  display: none;
}

/* Normalization */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

video::-webkit-media-controls {
  will-change: transform;
}
`.trim();

/**
 * The scaffold theme. It includes these features:
 *
 * - Define the default slide size.
 * - Set default style for `<section>`.
 * - Normalize `<h1>` heading style.
 * - Apply workaround for glitched video control on Chromium (https://github.com/marp-team/marpit/issues/205)
 *
 * @type {Theme}
 */
const scaffoldTheme = exports.scaffoldTheme = _theme.default.fromCSS(css, {
  [_symbol.default]: true
});
var _default = exports.default = scaffoldTheme;
export default exports;