import _postcss_plugin2 from "../helpers/postcss_plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.meta = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit PostCSS meta plugin.
 *
 * Parse CSS comment written in the format of `@key value`.
 *
 * @function meta
 * @param {Object} [opts]
 * @param {Object} [opts.metaType] An object for defined types for metadata.
 */
const meta = exports.meta = (0, _postcss_plugin.default)("marpit-postcss-meta", (opts = {}) => (css, {
  result
}) => {
  const metaType = opts.metaType || {};
  result.marpitMeta = result.marpitMeta || {};
  css.walkComments(comment => {
    comment.text.slice(0).replace(/^[*!\s]*@([\w-]+)\s+(.+)$/gim, (_, metaName, value) => {
      if (metaType[metaName] === Array) {
        // Array meta
        result.marpitMeta[metaName] = [...(result.marpitMeta[metaName] || []), value];
      } else {
        // String meta (default)
        result.marpitMeta[metaName] = value;
      }
    });
  });
});
var _default = exports.default = meta;
export default exports;