import _postcss_plugin2 from "../helpers/postcss_plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sectionSize = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit PostCSS section size plugin.
 *
 * Parse width and height declartaion on `section` selector.
 *
 * @function sectionSize
 */
const sectionSize = exports.sectionSize = (0, _postcss_plugin.default)("marpit-postcss-section-size", ({
  preferedPseudoClass
} = {}) => {
  const rootSectionMatcher = new RegExp(`^section${preferedPseudoClass ? `(${preferedPseudoClass})?` : ""}$`);
  return (css, {
    result
  }) => {
    const originalSize = result.marpitSectionSize || {};
    const detectedSize = {};
    const preferedSize = {};
    let matched;
    css.walkRules(rule => {
      if (rule.selectors.some(s => {
        matched = s.match(rootSectionMatcher);
        return !!matched;
      })) {
        rule.walkDecls(/^(width|height)$/, decl => {
          const {
            prop
          } = decl;
          const value = decl.value.trim();
          if (matched[1]) {
            preferedSize[prop] = value;
          } else {
            detectedSize[prop] = value;
          }
        });
      }
    });
    const width = preferedSize.width || detectedSize.width || originalSize.width;
    const height = preferedSize.height || detectedSize.height || originalSize.height;
    result.marpitSectionSize = {
      ...originalSize
    };
    if (width) result.marpitSectionSize.width = width;
    if (height) result.marpitSectionSize.height = height;
  };
});
var _default = exports.default = sectionSize;
export default exports;