import _inline_style2 from "../../helpers/inline_style";
import _wrap_tokens2 from "../../helpers/wrap_tokens";
import _plugin2 from "../../plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.advancedBackground = void 0;
var _inline_style = _interopRequireDefault(_inline_style2);
var _wrap_tokens = _wrap_tokens2;
var _plugin = _interopRequireDefault(_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit advanced background image plugin.
 *
 * Support the advanced features for background image, by using `<figure>`
 * element(s) instead of CSS backgrounds. It works by creating the isolated
 * layer into inline SVG.
 *
 * @function advancedBackground
 * @param {MarkdownIt} md markdown-it instance.
 */
function _advancedBackground(md) {
  md.core.ruler.after("marpit_directives_apply", "marpit_advanced_background", state => {
    let current;
    const newTokens = [];
    for (const t of state.tokens) {
      if (t.type === "marpit_inline_svg_content_open" && t.meta && t.meta.marpitBackground) {
        current = t;
        const {
          height,
          images,
          open,
          width
        } = t.meta.marpitBackground;
        open.attrSet("data-marpit-advanced-background", "content");

        // Aligned direction
        const direction = t.meta.marpitBackground.direction || "horizontal";

        // Split backgrounds
        const splitSide = t.meta.marpitBackground.split;
        if (splitSide) {
          open.attrSet("data-marpit-advanced-background-split", splitSide);
          const splitBgSize = t.meta.marpitBackground.splitSize || "50%";
          t.attrSet("width", `${100 - Number.parseFloat(splitBgSize.slice(0, -1))}%`);
          if (splitSide === "left") t.attrSet("x", splitBgSize);
          const style = new _inline_style.default(open.attrGet("style"));
          style.set("--marpit-advanced-background-split", splitBgSize);
          open.attrSet("style", style.toString());
        }

        // Add the isolated layer for background image
        newTokens.push(...(0, _wrap_tokens.wrapTokens)(state.Token, "marpit_advanced_background_foreign_object", {
          tag: "foreignObject",
          width,
          height
        }, (0, _wrap_tokens.wrapTokens)(state.Token, "marpit_advanced_background_section", {
          ...open.attrs.reduce((o, [k, v]) => ({
            ...o,
            [k]: v
          }), {}),
          tag: "section",
          id: undefined,
          "data-marpit-advanced-background": "background"
        }, (0, _wrap_tokens.wrapTokens)(state.Token, "marpit_advanced_background_image_container", {
          tag: "div",
          "data-marpit-advanced-background-container": true,
          "data-marpit-advanced-background-direction": direction
        }, (() => {
          const imageTokens = [];

          // Add multiple image elements
          for (const img of images) {
            const style = new _inline_style.default({
              "background-image": `url("${img.url}")`
            });

            // Image sizing
            if (img.size) style.set("background-size", img.size);

            // Image filter for backgrounds (Only in advanced BG)
            if (img.filter) style.set("filter", img.filter);
            imageTokens.push(...(0, _wrap_tokens.wrapTokens)(state.Token, "marpit_advanced_background_image", {
              tag: "figure",
              style: style.toString(),
              open: {
                meta: {
                  marpitBackgroundAlt: img.alt
                }
              }
            }));
          }
          return imageTokens;
        })()))), t);
      } else if (current && t.type === "marpit_inline_svg_content_close") {
        const {
          open,
          height,
          width
        } = current.meta.marpitBackground;

        // Apply styles
        const style = new _inline_style.default();
        if (open.meta && open.meta.marpitDirectives && open.meta.marpitDirectives.color) style.set("color", open.meta.marpitDirectives.color);

        // Add the isolated layer for pseudo contents (e.g. Page number)
        newTokens.push(t, ...(0, _wrap_tokens.wrapTokens)(state.Token, "marpit_advanced_background_foreign_object", {
          tag: "foreignObject",
          width,
          height,
          "data-marpit-advanced-background": "pseudo"
        }, (0, _wrap_tokens.wrapTokens)(state.Token, "marpit_advanced_pseudo_section", {
          ...open.attrs.reduce((o, [k, v]) => ({
            ...o,
            [k]: v
          }), {}),
          tag: "section",
          id: undefined,
          style: style.toString(),
          "data-marpit-advanced-background": "pseudo"
        })));
        current = undefined;
      } else {
        newTokens.push(t);
      }
    }
    state.tokens = newTokens;
  });

  // Renderer for advanced background image
  md.renderer.rules.marpit_advanced_background_image_open = (tokens, idx, options, _env, self) => {
    var _token$meta;
    const token = tokens[idx];
    const open = self.renderToken(tokens, idx, options);
    if ((_token$meta = token.meta) !== null && _token$meta !== void 0 && _token$meta.marpitBackgroundAlt) {
      return `${open}<figcaption>${md.utils.escapeHtml(token.meta.marpitBackgroundAlt)}</figcaption>`;
    }
    return open;
  };
}
const advancedBackground = exports.advancedBackground = (0, _plugin.default)(_advancedBackground);
var _default = exports.default = advancedBackground;
export default exports;