import * as _cssesc3 from "cssesc";
var _cssesc2 = "default" in _cssesc3 ? _cssesc3.default : _cssesc3;
import _postcss_plugin2 from "../helpers/postcss_plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.postprocess = exports.default = exports.containerQuery = void 0;
var _cssesc = _interopRequireDefault(_cssesc2);
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

const reservedNames = ["none", "inherit", "initial", "revert", "revert-layer", "unset"];
const marpitContainerQueryPseudoMatcher = /\bsection:marpit-container-query\b/g;

/**
 * Marpit PostCSS container query plugin.
 *
 * Add support of container queries for child elements of the `section` element.
 * (`@container` at-rule, and `cqw` `cqh` `cqi` `cqb` `cqmin` `cqmax` units)
 *
 * @function meta
 * @param {string|string[]} [containerName=undefined] Container name
 * @param {boolean} [escape=true] Set whether to escape container name
 */
const containerQuery = exports.containerQuery = (0, _postcss_plugin.default)("marpit-postcss-container-query", (containerName = undefined, escape = true) => css => {
  const containerNames = (Array.isArray(containerName) ? containerName : [containerName]).filter(name => name && !reservedNames.includes(name));
  const containerNameDeclaration = containerNames.length > 0 ? `\n  container-name: ${containerNames.map(name => escape ? (0, _cssesc.default)(name.toString(), {
    isIdentifier: true
  }) : name.toString()).join(" ")};` : "";
  const style = `
section:marpit-container-query {
  container-type: size;${containerNameDeclaration}
}
`.trim();
  if (css.first) {
    css.first.before(style);
  } else {
    css.append(style);
  }
});
const postprocess = exports.postprocess = (0, _postcss_plugin.default)("marpit-postcss-container-query-postprocess", () => css => css.walkRules(marpitContainerQueryPseudoMatcher, rule => {
  rule.selectors = rule.selectors.map(selector => selector.replace(marpitContainerQueryPseudoMatcher, ":where(section)"));
}));
var _default = exports.default = containerQuery;
export default exports;