import _postcss_plugin2 from "../../helpers/postcss_plugin";
import _font_size2 from "./font_size";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rem = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
var _font_size = _font_size2;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

const skipParsingMatcher = /("[^"]*"|'[^']*'|(?:attr|url|var)\([^)]*\))/g;

/**
 * Marpit PostCSS rem plugin.
 *
 * Replace `rem` unit to calculated value from CSS variable.
 *
 * @function rem
 */
const rem = exports.rem = (0, _postcss_plugin.default)("marpit-postcss-rem", () => css => css.walkDecls(decl => {
  decl.value = decl.value.split(skipParsingMatcher).map((v, i) => {
    if (i % 2) return v;
    return v.replace(/(\d*\.?\d+)rem\b/g, (_, num) => `calc(var(${_font_size.rootFontSizeCustomProp}, 1rem) * ${num})`);
  }).join("");
}));
var _default = exports.default = rem;
export default exports;