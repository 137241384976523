import _wrap_array2 from "../helpers/wrap_array";
import _wrap_tokens2 from "../helpers/wrap_tokens";
import _plugin2 from "../plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.container = void 0;
var _wrap_array = _wrap_array2;
var _wrap_tokens = _wrap_tokens2;
var _plugin = _interopRequireDefault(_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit container plugin.
 *
 * @function container
 * @param {MarkdownIt} md markdown-it instance.
 */
function _container(md) {
  const containers = (0, _wrap_array.wrapArray)(md.marpit.options.container);
  if (!containers) return;
  const target = [...containers].reverse();
  md.core.ruler.push("marpit_containers", state => {
    if (state.inlineMode) return;
    for (const cont of target) state.tokens = (0, _wrap_tokens.wrapTokens)(state.Token, "marpit_containers", cont, state.tokens);
  });
}
const container = exports.container = (0, _plugin.default)(_container);
var _default = exports.default = container;
export default exports;