import _split2 from "../helpers/split";
import _wrap_array2 from "../helpers/wrap_array";
import _wrap_tokens2 from "../helpers/wrap_tokens";
import _plugin2 from "../plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.slideContainer = exports.default = void 0;
var _split = _split2;
var _wrap_array = _wrap_array2;
var _wrap_tokens = _wrap_tokens2;
var _plugin = _interopRequireDefault(_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit slide container plugin.
 *
 * @function slideContainer
 * @param {MarkdownIt} md markdown-it instance.
 */
function _slideContainer(md) {
  const containers = (0, _wrap_array.wrapArray)(md.marpit.options.slideContainer);
  if (!containers) return;
  const target = [...containers].reverse();
  md.core.ruler.push("marpit_slide_containers", state => {
    if (state.inlineMode) return;
    const newTokens = [];
    for (const tokens of (0, _split.split)(state.tokens, t => t.meta && t.meta.marpitSlideElement === 1, true)) {
      if (tokens.length > 0) newTokens.push(...target.reduce((slides, conts) => (0, _wrap_tokens.wrapTokens)(state.Token, "marpit_slide_containers", conts, slides), tokens));
    }
    state.tokens = newTokens;
  });
}
const slideContainer = exports.slideContainer = (0, _plugin.default)(_slideContainer);
var _default = exports.default = slideContainer;
export default exports;