import _split2 from "../helpers/split";
import _plugin2 from "../plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.headingDivider = exports.default = void 0;
var _split = _split2;
var _plugin = _interopRequireDefault(_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit heading divider plugin.
 *
 * Start a new slide page at before of headings by prepending hidden `<hr>`
 * elements.
 *
 * @function headingDivider
 * @param {MarkdownIt} md markdown-it instance.
 */
function _headingDivider(md) {
  const {
    marpit
  } = md;
  md.core.ruler.before("marpit_slide", "marpit_heading_divider", state => {
    let target = marpit.options.headingDivider;
    if (marpit.lastGlobalDirectives && Object.prototype.hasOwnProperty.call(marpit.lastGlobalDirectives, "headingDivider")) target = marpit.lastGlobalDirectives.headingDivider;
    if (state.inlineMode || target === false) return;
    if (Number.isInteger(target) && target >= 1 && target <= 6) target = [...Array(target).keys()].map(i => i + 1);
    if (!Array.isArray(target)) return;
    const splitTag = target.map(i => `h${i}`);
    const splitFunc = t => t.type === "heading_open" && splitTag.includes(t.tag);
    const newTokens = [];
    for (const slideTokens of (0, _split.split)(state.tokens, splitFunc, true)) {
      const [token] = slideTokens;
      if (token && splitFunc(token) && newTokens.some(t => !t.hidden)) {
        const hr = new state.Token("hr", "", 0);
        hr.hidden = true;
        hr.map = token.map;
        newTokens.push(hr);
      }
      newTokens.push(...slideTokens);
    }
    state.tokens = newTokens;
  });
}
const headingDivider = exports.headingDivider = (0, _plugin.default)(_headingDivider);
var _default = exports.default = headingDivider;
export default exports;