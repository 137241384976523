import _plugin2 from "../plugin";
import _apply2 from "./image/apply";
import _parse2 from "./image/parse";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.image = exports.default = void 0;
var _plugin = _interopRequireDefault(_plugin2);
var _apply = _interopRequireDefault(_apply2);
var _parse = _interopRequireDefault(_parse2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit image plugin.
 *
 * @function image
 * @param {MarkdownIt} md markdown-it instance.
 */
function _image(md) {
  (0, _parse.default)(md);
  (0, _apply.default)(md);
}
const image = exports.image = (0, _plugin.default)(_image);
var _default = exports.default = image;
export default exports;