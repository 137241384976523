import _postcss_plugin2 from "../../helpers/postcss_plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rootReplace = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit PostCSS root replace plugin.
 *
 * Replace `:root` pseudo-class selector into `section`. It can add custom
 * pseudo class through `pseudoClass` option to make distinguishable from
 * `section` selector.
 *
 * @function rootReplace
 */
const rootReplace = exports.rootReplace = (0, _postcss_plugin.default)("marpit-postcss-root-replace", ({
  pseudoClass
} = {}) => css => css.walkRules(rule => {
  // Replace `:root` pseudo-class selectors into `section`
  rule.selectors = rule.selectors.map(selector => selector.replace(/(^|[\s>+~(])(?:section)?:root\b/g, (_, s) => `${s}section${pseudoClass || ""}`));
}));
var _default = exports.default = rootReplace;
export default exports;