import _postcss_plugin2 from "../../helpers/postcss_plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pseudoSelectorPrepend = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit PostCSS pseudo selector prepending plugin.
 *
 * Prepend `:marpit-container > :marpit-slide` pseudo selector to each selector
 * of Marpit theme CSS for modulized styling.
 *
 * @function pseudoSelectorPrepend
 */
const pseudoSelectorPrepend = exports.pseudoSelectorPrepend = (0, _postcss_plugin.default)("marpit-postcss-pseudo-selector-prepend", () => css => css.walkRules(rule => {
  const {
    type,
    name
  } = rule.parent || {};
  if (type === "atrule" && name === "keyframes") return;
  rule.selectors = rule.selectors.map(selector => {
    if (/^section(?![\w-])/.test(selector)) return `:marpit-container > :marpit-slide${selector.slice(7)}`;
    if (selector.startsWith(":marpit-container")) return selector;
    return `:marpit-container > :marpit-slide ${selector}`;
  });
}));
var _default = exports.default = pseudoSelectorPrepend;
export default exports;