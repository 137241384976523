import * as _jsYaml3 from "js-yaml";
var _jsYaml2 = "default" in _jsYaml3 ? _jsYaml3.default : _jsYaml3;
import _directives2 from "./directives";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.yaml = exports.default = void 0;
var _jsYaml = _interopRequireWildcard(_jsYaml2);
var _directives = _interopRequireDefault(_directives2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _getRequireWildcardCache(e) {
  if ("function" != typeof WeakMap) return null;
  var r = new WeakMap(),
    t = new WeakMap();
  return (_getRequireWildcardCache = function (e) {
    return e ? t : r;
  })(e);
}
function _interopRequireWildcard(e, r) {
  if (!r && e && e.__esModule) return e;
  if (null === e || "object" != typeof e && "function" != typeof e) return {
    default: e
  };
  var t = _getRequireWildcardCache(r);
  if (t && t.has(e)) return t.get(e);
  var n = {
      __proto__: null
    },
    a = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) {
    var i = a ? Object.getOwnPropertyDescriptor(e, u) : null;
    i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u];
  }
  return n.default = e, t && t.set(e, n), n;
}
/** @module */

const createPatterns = keys => {
  const set = new Set();
  for (const k of keys) {
    const normalized = "_?" + k.replace(/[.*+?^=!:${}()|[\]\\/]/g, "\\$&");
    set.add(normalized);
    set.add(`"${normalized}"`);
    set.add(`'${normalized}'`);
  }
  return [...set.values()];
};
const yamlSpecialChars = `["'{|>~&*`;
function parse(text) {
  try {
    const obj = _jsYaml.default.load(text, {
      schema: _jsYaml.FAILSAFE_SCHEMA
    });
    if (obj === null || typeof obj !== "object") return false;
    return obj;
  } catch (e) {
    return false;
  }
}
function convertLoose(text, looseDirectives) {
  const keyPattern = `(?:${createPatterns(looseDirectives).join("|")})`;
  const looseMatcher = new RegExp(`^(${keyPattern}\\s*:)(.+)$`);
  let normalized = "";
  for (const line of text.split(/\r?\n/)) normalized += `${line.replace(looseMatcher, (original, prop, value) => {
    const trimmed = value.trim();
    if (trimmed.length === 0 || yamlSpecialChars.includes(trimmed[0])) return original;
    const spaceLength = value.length - value.trimLeft().length;
    const spaces = value.substring(0, spaceLength);
    return `${prop}${spaces}"${trimmed.split("\"").join("\\\"")}"`;
  })}\n`;
  return normalized.trim();
}

/**
 * Parse text as YAML by using js-yaml's FAILSAFE_SCHEMA.
 *
 * @function yaml
 * @param {String} text Target text.
 * @param {boolean|string[]} [looseDirectives=false] By setting `true`, it try
 *     to parse as loose YAML only in defined Marpit built-in directives. You
 *     may also extend target keys for loose parsing by passing an array of
 *     strings.
 * @returns {Object|false} Return parse result, or `false` when failed to parse.
 */

const yaml = (text, looseDirectives = false) => parse(looseDirectives ? convertLoose(text, [..._directives.default, ...(Array.isArray(looseDirectives) ? looseDirectives : [])]) : text);
exports.yaml = yaml;
var _default = exports.default = yaml;
export default exports;