import _postcss_plugin2 from "../helpers/postcss_plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pagination = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit PostCSS pagination plugin.
 *
 * Marpit uses `section::after` to show the pagination on each slide. It defines
 * in the scaffold theme.
 *
 * This plugin will comment out a `content` declaration defined in any
 * `section::after` of the root, to prevent override the defined attribute for
 * paginating.
 *
 * @function pagination
 */
const pagination = exports.pagination = (0, _postcss_plugin.default)("marpit-postcss-pagination", () => css => {
  css.walkRules(rule => {
    if (rule.selectors.some(selector => /^section(?![\w-])[^\s>+~]*::?after$/.test(selector.replace(/\[.*?\]/g, "")))) rule.walkDecls("content", decl => {
      if (!decl.value.includes("attr(data-marpit-pagination)")) decl.replaceWith(`${decl.raw("before")}/* ${decl.toString()}; */`);
    });
  });
});
var _default = exports.default = pagination;
export default exports;