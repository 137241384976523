import _split2 from "../helpers/split";
import _wrap_tokens2 from "../helpers/wrap_tokens";
import _plugin2 from "../plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.inlineSVG = exports.default = void 0;
var _split = _split2;
var _wrap_tokens = _wrap_tokens2;
var _plugin = _interopRequireDefault(_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit Inline SVG plugin.
 *
 * @function inlineSVG
 * @param {MarkdownIt} md markdown-it instance.
 */
function _inlineSVG(md) {
  const {
    marpit
  } = md;
  md.core.ruler.after("marpit_directives_parse", "marpit_inline_svg", state => {
    if (!(marpit.inlineSVGOptions && marpit.inlineSVGOptions.enabled) || state.inlineMode) return;
    const {
      themeSet,
      lastGlobalDirectives
    } = marpit;
    const w = themeSet.getThemeProp(lastGlobalDirectives.theme, "widthPixel");
    const h = themeSet.getThemeProp(lastGlobalDirectives.theme, "heightPixel");
    const newTokens = [];
    for (const tokens of (0, _split.split)(state.tokens, t => t.meta && t.meta.marpitSlideElement === 1, true)) {
      if (tokens.length > 0) {
        for (const t of tokens) if (t.meta && t.meta.marpitSlideElement) delete t.meta.marpitSlideElement;
        newTokens.push(...(0, _wrap_tokens.wrapTokens)(state.Token, "marpit_inline_svg", {
          tag: "svg",
          "data-marpit-svg": "",
          viewBox: `0 0 ${w} ${h}`,
          open: {
            meta: {
              marpitSlideElement: 1
            }
          },
          close: {
            meta: {
              marpitSlideElement: -1
            }
          }
        }, (0, _wrap_tokens.wrapTokens)(state.Token, "marpit_inline_svg_content", {
          tag: "foreignObject",
          width: w,
          height: h
        }, tokens)));
      }
    }
    state.tokens = newTokens;
  });
}
const inlineSVG = exports.inlineSVG = (0, _plugin.default)(_inlineSVG);
var _default = exports.default = inlineSVG;
export default exports;