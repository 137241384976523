import _postcss_plugin2 from "../../helpers/postcss_plugin";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rootIncreasingSpecificity = exports.pseudoClass = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

const pseudoClass = exports.pseudoClass = ":marpit-root";
const matcher = new RegExp(`\\b(?:section)?${pseudoClass}\\b`, "g");

/**
 * Marpit PostCSS root increasing specificity plugin.
 *
 * Replace specific pseudo-class selector to `:where(section):not([\20 root])`,
 * to increase specificity. `:marpit-root` is always added to `section` selector
 * by root replace plugin so `:where(section):not([\20 root])` must always match
 * too (HTML does not allow U+0020 SPACE in the attribute name.).
 *
 * @function rootIncreasingSpecificity
 */
const rootIncreasingSpecificity = exports.rootIncreasingSpecificity = (0, _postcss_plugin.default)("marpit-postcss-root-increasing-specificity", () => css => css.walkRules(rule => {
  rule.selectors = rule.selectors.map(selector => selector.replace(matcher, ":where(section):not([\\20 root])"));
}));
var _default = exports.default = rootIncreasingSpecificity;
export default exports;