import _postcss_plugin2 from "../../helpers/postcss_plugin";
import _parse2 from "./parse";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importSuppress = exports.default = void 0;
var _postcss_plugin = _interopRequireDefault(_postcss_plugin2);
var _parse = _interopRequireDefault(_parse2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/** @module */

/**
 * Marpit PostCSS import suppress plugin.
 *
 * Comment out `@import` / `@import-theme` rules that have imported theme.
 *
 * This plugin is useful to prevent the inline style's rolled-up theme import by
 * unexpected order.
 *
 * @function importSuppress
 * @param {ThemeSet} themeSet ThemeSet instance.
 */
const importSuppress = exports.importSuppress = (0, _postcss_plugin.default)("marpit-postcss-import-suppress", themeSet => ({
  plugins: [(0, _parse.default)(), (0, _postcss_plugin.default)("marpit-postcss-import-suppress", () => css => {
    css.walk(node => {
      if (node.marpitImportParse && themeSet.has(node.marpitImportParse)) node.replaceWith(`${node.raw("before")}/* ${node.toString()}; */`);
    });
  })()]
}));
var _default = exports.default = importSuppress;
export default exports;